<template>
  <img v-if="source" :alt="source.data.alt ?? ''"
       :src="source.thumb ?? source.original"
       :srcset="srcset"
       :title="source.data.title ?? ''"
       :sizes="sizes"
       loading="lazy"
  >
</template>

<script>
export default {
	name: 'Image',
	props: {
		sizes: {
			type: String,
			default: ''
		},
		source: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			imageResolutions: {
				lg: 1920,
				md: 1024,
				original: 1921,
				sm: 800,
				thumb: 150
			}
		}
	},
	computed: {
		srcset() {
			const resolutions = Object.keys(this.imageResolutions)
			return Object.entries(this.source)
				.filter(src => resolutions.includes(src[0]))
				.sort((srcA, srcB) => this.imageResolutions[srcA[0]] - this.imageResolutions[srcB[0]])
				.map(src => `${src[1]} ${this.imageResolutions[src[0]]}w`)
				.join(', ')
		}
	}
}
</script>